<template>
  <div>
    <!--    管理员列表-->
    <div class="app-container">
      <el-row
        v-loading="loading"
        element-loading-text="加载中"

        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
        stripe
      >
        <el-col :span="3">
          <b>管理员列表</b>
        </el-col>
        <el-col :span="3" :pull="16">
          <el-button size="small" icon="el-icon-plus" type="success" @click="ifAdd = true">添加</el-button>
        </el-col>
      </el-row>
      <el-table
        stripe
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background: '#eee', color: '#333' }"
      >
        <el-table-column type="index" ></el-table-column>
        <el-table-column prop="username" label="账号" align="center" ></el-table-column>
        <el-table-column prop="name" label="姓名" align="center" ></el-table-column>
        <el-table-column prop="username" label="手机号" align="center" ></el-table-column>
        <el-table-column prop="createTime" label="添加时间" align="center" ></el-table-column>
        <el-table-column label="操作" align="center" width="260" fixed="right">
          <template slot-scope="scope">
            <el-button v-show="scope.row.deleteTime == null"
                       size="mini"
                       type="warning"
                       icon="el-icon-refresh"
                       @click="handleRest(scope.$index, scope.row)">
              重置密码
            </el-button>

            <el-button v-show="scope.row.deleteTime == null"
                       size="mini"
                       type="danger"
                       icon="el-icon-delete"
                       @click="handleDelete(scope.$index, scope.row)">
              删除
            </el-button>

            <el-button v-show="scope.row.deleteTime != null"
                       size="mini"
                       type="danger"
                       icon="el-icon-delete"
                       :disabled="true"
                       @click="handleCancel(scope.$index, scope.row)">
              已删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>
    </div>



    <!--    新增管理员弹窗-->
    <el-dialog
      title="新增管理员"
      align="center"
      :visible.sync="ifAdd"
      :show-close="false"
      width="50%">

      <div style="width: 70%">

      <el-form ref="addForm" :model="addForm" :rules="rules" label-width="40%">
        <el-form-item label="管理员姓名"  prop="name" >
          <el-input v-model="addForm.name" placeholder="请输入管理员真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="username">
          <el-input v-model="addForm.username" placeholder="请输入手机号" ></el-input>
        </el-form-item>

        <br>
        <sapn>新增后，新管理员可使用手机号进行登录，初始密码为：123456</sapn>

        <el-form-item>
          <br>
          <el-button size="small" @click="ifAdd = false, addForm={}">取消</el-button>
          <el-button size="small" type="primary" @click="addSubmit">确定新增</el-button>

        </el-form-item>
      </el-form>
        </div>
    </el-dialog>

  </div>
</template>

<script>

import { request } from '@/network/network'
import  {formatDate} from '@/utils/date'
import { phone} from '@/utils/rules'

export default {

  data() {

    return {
      loading: true,
      tableData: [],
      total: 0,
      currentPage: 0,
      //新增管理员表单数据
      addForm:{
        name:"",
        username:"",
        // password:"",
        did:0
      },
      rules:{
        name: [
          { required: true, message: '姓名不能为空', trigger: 'blur' },
          { min: 1, max: 10, message: '不能多于10字', trigger: 'blur' }
        ],
        username: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
      },
      ifAdd:false,
      adminInformation: JSON.parse(sessionStorage.getItem("information"))
    }
  },
  methods: {
    //分页插件绑定事件
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.getAdmins(val)
    },

    // 确定新增
    addSubmit(){
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '请稍等',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });

          this.checkAdd(this.addForm.username)
            .then(res => {
              loading.close();
              if(res.data.length == 0){
                this.addForm.did = this.adminInformation.did
                this.addAdmin(this.addForm)
                  .then((res) => {
                    this.$message({
                      message: "新增成功！",
                      type: "success",
                      duration: 1000,
                    });
                    this.getAdmins();
                    this.ifAdd = false
                  })
                  .catch((err) => {
                    this.$message({
                      message: "发生错误，新增失败！",
                      type: "error",
                      duration: 1000,
                    });
                    this.getAdmins();

                    this.ifAdd = false
                  });
              }
              else{
                this.$message({
                  message: "新增管理员失败,该手机号已存在！",
                  type: "error",
                  duration: 2000,
                });
              }
            })
            .catch((err) => {
              loading.close();
              this.$message({
                message: "发生错误，请重试！",
                type: "error",
                duration: 1000,
              });

            });

        } else {
          console.log('error submit!!');
          return false;
        }
      });


    },

    //确定重置
    handleRest(index,row) {
      this.$confirm("是否要重置该管理员密码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.restPassword(row.id)
          .then((res) => {
            this.$message({
              message: "重置成功！",
              type: "success",
              duration: 1000,
            });
            this.getAdmins();
          })
          .catch((err) => {
            this.$message({
              message: "发生错误，重置失败！",
              type: "error",
              duration: 1000,
            });
            this.getAdmins();
          });
      })
        .catch(() => {
          row.purchase = false;
          this.$message({
            message: "操作取消！",
            type: "info",
            duration: 1000,
          });
        });

    },

   // 删除按钮
    handleDelete(index, row) {
      this.$confirm("是否要进行该删除操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteAdmin(row.id)
            .then((res) => {
              this.$message({
                message: "删除成功！",
                type: "success",
                duration: 1000,
              });
              this.getAdmins();

            })
            .catch((err) => {
              this.$message({
                message: "发生错误，删除失败！",
                type: "error",
                duration: 1000,
              });
              this.getAdmins();

            });
        })
        .catch(() => {
          row.purchase = false;
          this.$message({
            message: "操作取消！",
            type: "info",
            duration: 1000,
          });
        });
    },

    getAdmins(page, type){
      return request({
        url: '/admin/admins',
        method:'get',
        params:{
          page: page,
          type: 1
        }
      }).then(res => {
        this.total = res.data.total
        let temp = res.data.list

        // this.tableData = temp.map(item => {
        //
        //     // item.createTime = formatDate(item.createTime)
        //     return item
        //
        //
        // })

        this.tableData = temp.filter(item => item.username != 'admin')
        this.loading = false
      })
    },
    addAdmin(admin){
      return request({
        url: '/admin/addAdmin',
        method: 'post',
        data: admin
      })
    },

    restPassword(id){
      return request({
        url: '/admin/restPassword',
        method: 'get',
        params: {
          id: id
        }
      })
    },
    deleteAdmin(id){
      return request({
        url: '/admin/deleteAdmin',
        method: 'get',
        params: {
          id: id
        }
      })
    },
    checkAdd(phone){
      return request({
        url: '/admin/checkAdd',
        method: 'get',
        params: {
          phone: phone
        }
      })

    },
  },
  created() {
    this.getAdmins();

  },
};
</script>
<style scoped>
.input-width {
  width: 80%;
}
</style>

